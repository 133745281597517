.narrow-page {
  max-width: 364px;
  float: right;
}

.narrow-page img {
  max-width: 100%;
  padding-bottom: 25px;
}

.page {
  max-width: 800px;
  padding-left: 100px;
  margin-left: auto;
}

.page img {
  width: 100%;
  padding-bottom: 25px;
  padding-top: 25px;
  pointer-events: none;
}

.page img.portrait {
  width: 70%;
  max-width: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.page ul {
  padding-left: 0px;
}

.page li {
  list-style: none;
}

.page-title {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: #414042;
  margin-bottom: 15px;
}

.blur-up {
  filter: blur(10px);
}

.blur-up.lazyloaded {
  filter: blur(0);
  transition: 0.2s;
}


@media screen and (max-width: 1000px) {
  .post-list {
    margin-bottom: 50px;
  }

  .page {
    max-width: 260px;
    float: right;
  }

  .page {
    max-width: 450px;
    padding-left: 0;
  }

  .page-title {
    font-size: 22px;
  }

  .page img {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 66.6667%;
  /* You define this doing image height / width * 100% */
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 500px) {
  .narrow-page img {
    height: 265px;
  }
}
