.page-list {
  list-style-type: none;
  text-align: right;
  color : #6D6E71;
  line-height: 1.25;
  margin: 0 0 80px;
}

.page-list li.title {
  margin-top: 5px;
  margin-bottom: 17px;
}

.page-list a {
  text-decoration: none;
  color : #414042;
  font-weight: 500;
}

.thumb-col {
  max-width: 390px;
  padding-left: 25px;
  margin-left: auto;
}

.thumb-image {
  width: 100%;
  height: auto;
  max-width: 365px;
  max-height: 243.333px;
}

.alignment-fix {
  margin-bottom: -5px;
}

/* // mobile mode */
@media screen and (max-width: 500px) {
  .thumb-col {
    padding-left: 0;
  }
}
@media screen and (max-width: 1000px) {
  .page-list {
    margin-bottom: 50px;
  }

  .alignment-fix {
    margin-bottom: -3px;
  }
}
@media screen and (min-width: 1200px) {
  .thumb-image {
    width: 365px;
    height: 243.333px;
  }
}
