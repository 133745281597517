
.sidebar {
  padding-right: 75px;
}

.navbar {
  padding-bottom: 25px;
}

.navbar-icon {
  width: 20px;
  margin-top: 2px;
}

.navbar-toggler {
  border: none;
  padding: 0;
}

.menu {
  padding-top: 105px;
  list-style-type: none;
}

.menu li a {
  font-size: 29px;
  color : #A7A9AC;
  text-decoration: none;
}

.menu li.active a {
  color: #414042;
}

.menu-nav {
  list-style-type: none;
  padding-top: 15px;
  line-height: 28px;
}

.menu-nav li a {
  font-size: 18px;
  color : #A7A9AC;
  text-decoration: none;
  line-height: 28px;
}

.menu-nav li.active a {
  color: #414042;
}
