.logo {
  width: 156.85px;
  height: 37px;
}

.logo-small {
  width: 100px;
  height: 23.5833px;
}

.logo-large {
  width: 280px;
  max-width: 50%;
  height: auto;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
