body,
html {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 300;
  color: #6D6E71;
}

a {
  color: rgb(40%, 40%, 40%);
}

a:hover {
  color: black;
}

.turbolinks-progress-bar {
  background-color: #6D6E71;
}

.frame {
  margin: 70px;
}

.container {
  padding: 0;
  max-width: 1150px;
}

.col,
.col-12,
.col-sm,
.navbar,
.navbar-brand,
.row {
  padding: 0;
  margin: 0;
}

.copyright {
  margin-top: 40px;
  font-size: 18px;
  color: #A7A9AC;
}
// mobile mode
@media screen and (max-width: 700px) {
  .frame {
    margin: 10%;
  }
}
@media screen and (max-width: 1000px) {
  body,
  html {
    font-size: 16px;
  }

  .copyright {
    font-size: 14px;
  }
}
@media screen and (max-width: 1000px) {
  .hide-small {
    display: none;
  }
}
@media screen and (min-width: 1001px) {
  .hide-large {
    display: none;
  }
}
